div.saveChanges {
  position: fixed;
  bottom: 2vh;
  left: 2vh;
  width: calc(100% - 4vh);
  padding: 0.5em;
  background-color: #262836;
  animation: save-change-entrance 0.5s forwards;
  border-radius: 0.5em;
}

@keyframes save-change-entrance {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes invalid-pulse {
  0% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
div#ArmiesBuilderPage h1 {
  color: #F8DC97;
  font-size: 3em;
  padding-left: 0.5em;
}
div#ArmiesBuilderPage div.armiesManager {
  padding: 0 0.5em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  min-height: 80vh;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview {
  grid-column: 1/2;
  grid-row: 1/3;
  position: relative;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox {
  background-color: #2C2F40;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  width: 95%;
  border-radius: 0.5em;
  position: absolute;
  top: 0;
  left: 2.5%;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox p {
  padding: 0 0.5em;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox p.faded {
  color: #464B66;
  font-style: italic;
  text-align: center;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox p.info {
  width: 80%;
  align-self: center;
  color: #E2E2E2;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox.noPreview {
  align-items: center;
  justify-content: center;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox > div.imgContainer {
  width: 10%;
  position: relative;
  align-self: center;
}
div#ArmiesBuilderPage div.armiesManager div.pieceInfoPreview div.pieceInfoPreviewBox > div.imgContainer > img {
  width: 100%;
  height: 100%;
}
div#ArmiesBuilderPage div.armiesManager div.piecesList {
  grid-column: 2/3;
  grid-row: 1/2;
}
div#ArmiesBuilderPage div.armiesManager div.piecesList div.pieceLibrary {
  display: flex;
  padding: 0.5em;
  flex-flow: row wrap;
}
div#ArmiesBuilderPage div.armiesManager div.piecesList div.pieceLibrary div.libraryPiece {
  width: 8%;
  background-color: #2C2F40;
  margin: 0.5em;
  border-radius: 0.5em;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
div#ArmiesBuilderPage div.armiesManager div.piecesList div.pieceLibrary div.libraryPiece:hover {
  background-color: #464B66;
}
div#ArmiesBuilderPage div.armiesManager div.piecesList div.pieceLibrary div.libraryPiece:hover > img {
  transform: translateY(-5%);
}
div#ArmiesBuilderPage div.armiesManager div.piecesList div.pieceLibrary div.libraryPiece img {
  width: 100%;
  transition: transform 0.25s ease-out;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard {
  grid-column: 2/3;
  grid-row: 2/3;
  position: relative;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.pointBuyCounter {
  position: absolute;
  top: 10%;
  left: 0;
  font-size: 2em;
  color: #464B66;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.pointBuyCounter p {
  margin-bottom: 0;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.pointBuyCounter .invalid {
  color: #ff1b36;
  display: inline-block;
  animation: invalid-pulse 0.5s forwards;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.pointBuyCounter .subtitle {
  font-size: 0.5em;
  margin-top: 0.25em;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding {
  height: 100%;
  width: 100%;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding div.chessBoard {
  display: grid;
  height: 0;
  padding-top: 12.5%;
  position: relative;
  border-radius: 1em;
  overflow: hidden;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding div.chessBoard div.lowerBoardPiece {
  position: absolute;
  height: 100%;
  width: 12.5%;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding div.chessBoard div.lowerBoardPiece > img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  cursor: pointer;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding div.chessBoard div.lowerBoardSquare {
  position: absolute;
  font-size: 0.5em;
  padding-top: 12.5%;
  width: 12.5%;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding div.chessBoard div.lowerBoardSquare.light {
  background-color: #F8DC97;
}
div#ArmiesBuilderPage div.armiesManager div.lowerBoard div.chessBoardPadding div.chessBoard div.lowerBoardSquare.dark {
  background-color: #3A4377;
}

/*# sourceMappingURL=ArmiesBuilderPage.css.map */
