div.saveChanges {
  position: fixed;
  bottom: 2vh;
  left: 2vh;
  width: calc(100% - 4vh);
  padding: 0.5em;
  background-color: #262836;
  animation: save-change-entrance 0.5s forwards;
  border-radius: 0.5em;
}

@keyframes save-change-entrance {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes invalid-pulse {
  0% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
div#DecksBuilderPage h1 {
  color: #F8DC97;
  font-size: 3em;
  padding-left: 0.5em;
}
div#DecksBuilderPage div.decksManager {
  padding: 0 0.5em;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
div#DecksBuilderPage div.decksManager div.cardListPreview {
  grid-column: 1/2;
  position: relative;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox {
  background-color: #2C2F40;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  width: 95%;
  border-radius: 0.5em;
  position: absolute;
  top: 0;
  left: 2.5%;
  padding: 0.5rem;
  align-items: center;
  overflow: auto;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox h2.cardListingTitle {
  font-size: 1.5rem;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox div.listedCard {
  width: 80%;
  display: flex;
  padding: 0.5rem;
  margin: 0.1rem;
  font-size: 1.25rem;
  border-radius: 0.25rem;
  background-color: #262836;
  cursor: pointer;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox div.listedCard:hover {
  background-color: #464B66;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox div.listedCard span.chaosValue {
  margin-right: 1rem;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox div.listedCard span.cardQuantity {
  margin: 0 1rem 0 auto;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox div.libDragReceiverOuter {
  background-color: #262836;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#DecksBuilderPage div.decksManager div.cardListPreview div.cardListBox div.libDragReceiverOuter div.dragHereNotice {
  text-align: center;
  color: #464B66;
  font-size: 1.25rem;
}
div#DecksBuilderPage div.decksManager div.cardsList {
  grid-column: 2/3;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary {
  display: flex;
  padding: 0.5em;
  flex-flow: row wrap;
  position: relative;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard {
  width: 10em;
  min-height: 14em;
  background-color: #2C2F40;
  margin: 0.5em;
  cursor: pointer;
  border: 1px solid #262836;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: transform 0.25s ease-out, background-color 0.5s ease;
  position: relative;
  color: #F8DC97;
  position: relative;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard:hover {
  background-color: #464B66;
  transform: translateY(-5%);
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard.disabled {
  cursor: not-allowed;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard.disabled:hover {
  background-color: #2C2F40;
  transform: none;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.cardTitle {
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.cardChaosScore {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.cardChaosScore div.speed {
  margin-left: auto;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.cardExpac {
  position: absolute;
  bottom: 0.25rem;
  text-align: center;
  width: 100%;
  color: #161823;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.limitReached {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.limitReached.limitBackground {
  background-color: #2C2F40;
  opacity: 0.5;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.libraryCard div.limitReached.limitText {
  text-align: center;
  user-select: none;
  color: black;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.listDragReceiver {
  background-color: #262836;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
div#DecksBuilderPage div.decksManager div.cardsList div.cardLibrary div.listDragReceiver div.dragHereNotice {
  text-align: center;
  color: #464B66;
  font-size: 1.25rem;
}

/*# sourceMappingURL=DecksBuilderPage.css.map */
