* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

div.app {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 60% 1fr;
}

div.boardLeftColumn, div.boardRightColumn {
  display: flex;
  flex-flow: column nowrap;
}
div.boardLeftColumn p.playerToMove, div.boardRightColumn p.playerToMove {
  color: #E2E2E2;
  font-style: italic;
}

div.chessBoardColumn {
  grid-column: 2/3;
  width: 80%;
  max-width: 75vh;
  justify-self: center;
  display: flex;
  flex-flow: column nowrap;
}

/*# sourceMappingURL=App.css.map */
