div#ArmiesPage h1.pageHeading {
  color: #F8DC97;
  font-size: 3em;
  padding-left: 0.5em;
}
div#ArmiesPage p.pageSubtitle {
  color: #E2E2E2;
  padding-left: 1.5em;
  font-size: 1em;
}
div#ArmiesPage div.armiesButtonRow {
  padding: 0 1.5em;
  font-size: 1em;
}
div#ArmiesPage div.armiesListing {
  font-size: 1em;
  padding: 0 1.5em;
  display: flex;
  flex-flow: row wrap;
}

div.armyPreview {
  display: inline-block;
  background-color: #2C2F40;
  max-width: 20em;
  min-width: 10em;
  position: relative;
  padding: 0.5em;
  margin: 1em;
  border-radius: 0.5em;
  cursor: pointer;
  transform: translateY(0);
  transition: transform 0.25s ease-out;
}
div.armyPreview:hover {
  transform: translateY(-0.5em);
}
div.armyPreview:hover div.delete {
  opacity: 1;
}
div.armyPreview div.delete {
  opacity: 0;
  transition: opacity 0.25s ease, color 0.25s ease-in-out;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #E2E2E2;
}
div.armyPreview div.delete:hover {
  color: #464B66;
}
div.armyPreview h3.ArmyName {
  color: #F8DC97;
}
div.armyPreview div.pieces > p {
  color: #464B66;
}

/*# sourceMappingURL=ArmiesPage.css.map */
