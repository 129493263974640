div#PiecePromotionHead {
  background-color: #262836;
  height: 12.5%;
  width: 12.5%;
  position: absolute;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.25);
}
div#PiecePromotionHead img {
  height: 100%;
  width: 100%;
}

div#PiecePromotionBody {
  background-color: #262836;
  height: 12.5%;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
div#PiecePromotionBody div.promotionPiece {
  height: 100%;
  padding: 0.5rem;
  cursor: pointer;
}
div#PiecePromotionBody div.promotionPiece:hover {
  background-color: #2C2F40;
}
div#PiecePromotionBody div.promotionPiece img {
  height: 100%;
  width: 100%;
}

/*# sourceMappingURL=PiecePromotionUI.css.map */
